<template>
  <div>
    <NavbarMobile style="z-index: 100; margin: auto" />
    <div class="body">
      <div id="sheet1" style="position: relative; width: 430px; height: 455px">
        <img id="bg1" src="../assets/images/homemobile/n_40.png" />
        <div id="title1" style="font-weight: 900">
          <span>何かが<br />起きたとしても<br />美味しいものは<br />食べたくなる。</span>
        </div>
      </div>

      <div id="sheet2" style="position: relative; width: 430px; height: 770px">
        <div id="back1">
          <span style="font-weight: 900">災害</span>
        </div>
        <div id="back2">
          <span>in case of <br />emergency</span>
        </div>
        <div id="title2" style="font-weight: 900">
          <span>いざという時に<br />美味しい備え。</span>
        </div>
        <img id="image01" src="../assets/images/homemobile/n_21122_bimistock_image_01@2x.png" />
        <img id="image02" src="../assets/images/homemobile/n_3258.png" />
        <div id="text1">
          <span>BimiStock </span><span style="font-size: 16px">ビミストック</span><span>
            は、３か月ごとに、<br />選りすぐりの美味しい保存食をお届けする、<br />手間いらずの防災食定期便です。</span><br />
        </div>
        <img id="image03" src="../assets/images/homemobile/n_3287.png" />
      </div>

      <div id="sheet3">
        <div style="position: relative; width: 430px; height: 600px">
          <img class="bg2" src="../assets/images/homemobile/n_21122_bimistock_image_11.png" />
          <svg class="square1_1">
            <rect class="square2" rx="0" ry="0" x="0" y="0" width="364" height="380"></rect>
          </svg>
          <div class="m_0_1">
            <span>01</span>
          </div>
          <div class="merit_1">
            <span style="font-style: italic">Merit</span>
          </div>
          <div class="m_title_1">
            <span>手間いらずで簡単に防災食を備蓄できます</span><br />
          </div>
          <div class="m_text_1">
            <span>３か月ごとに新しい備蓄用の保存食セットを自動的にお届けします。仕事や子育てに忙しいご家庭でも、消費期限の管理や入れ替えに時間・労力をかけることなく災害に備えていただけます。</span><br />
          </div>
        </div>

        <div style="position: relative; width: 430px; height: 600px">
          <img class="bg2" src="../assets/images/homemobile/n_21122_bimistock_image_12_.png" />
          <svg class="square1">
            <rect class="square2" rx="0" ry="0" x="0" y="0" width="364" height="420"></rect>
          </svg>
          <div class="m_0">
            <span>02</span>
          </div>
          <div class="merit">
            <span style="font-style: italic">Merit</span>
          </div>
          <div class="m_title">
            <span>災害時でも、食べなれたいつもの美味しい食事ができます</span><br />
          </div>
          <div class="m_text">
            <span>日本全国から集めた、バリエーション豊かで美味しい、常温保存食品をお届けします。美味しい食事は、お腹を満たすだけでなく、不安な気持ちやストレスを和らげることも期待できます。特にお子さまには少しでも安心感を与えてあげたいですよね。<br /></span><br />
          </div>
        </div>

        <div style="position: relative; width: 430px; height: 600px">
          <img class="bg2" src="../assets/images/homemobile/n_21122_bimistock_image_14.png" />
          <svg class="square1">
            <rect class="square2" rx="0" ry="0" x="0" y="0" width="364" height="420"></rect>
          </svg>
          <div class="m_0">
            <span>03</span>
          </div>
          <div class="merit">
            <span style="font-style: italic">Merit</span>
          </div>
          <div class="m_title">
            <span>普段の食卓での一品や忙しい時の時短料理として</span><br />
          </div>
          <div class="m_text">
            <span>普段使い用として作られた、美味しくて上質な保存食品をお届けします。３か月の備蓄期間が過ぎた後は、毎日の食卓のひと品や時短料理としてお使いください。防災専用備蓄食のような追加費用ではなく、月々の食費の一部で防災対策ができるというイメージです。</span><br />
          </div>
        </div>
      </div>

      <div id="sheet4" style="position: relative; width: 430px; height: 1310px">
        <img id="bg3" src="../assets/images/homemobile/AdobeStock_129693242.png" />
        <div id="back3">
          <span style="font-weight: 900">備蓄</span>
        </div>
        <div id="back4">
          <span style="font-weight: 900">防災食</span>
        </div>
        <div id="back5">
          <span>Rolling<br />Stock</span>
        </div>
        <div id="title3">
          <span style="font-weight: 900">届く・備える・食べる</span>
        </div>
        <div id="text2">
          <span>災害時、ご自身や大切なご家族を守るためには、最低３日分の備えが必要です！ <br />３か月ごとに手間いらずで届く
            BimiStock ビミストックで<br />政府も推奨する</span>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" style="color: rgba(255, 104, 47, 1); text-decoration: underline" v-on="on">ローリングストック</span>
            </template>
            <div style="max-width: 380px">
              <span>
                ※ローリングストックとは、日常的に非常食を食べ、食べた分を買い足すというサイクルを繰り返すことで常に新しい食料を備蓄する方法です。廃棄ロスの観点からも注目されてます。
              </span>
            </div>
          </v-tooltip><span>をはじめましょう</span>
        </div>
        <div id="image04">
          <img id="image05" src="../assets/images/homemobile/n_3238.png" />
          <div id="mark1">
            <span>届く</span>
          </div>
          <div id="mark2">
            <span>備える</span>
          </div>
          <div id="mark3">
            <span>食べる</span>
          </div>
          <img id="image06" src="../assets/images/homemobile/n_3236.png" />
        </div>
        <div id="text3">
          <span>地震・台風・暴風・大雨・大雪・噴火、そして新型コロナをはじめとする感染症など、近年日本各地で災害が頻発しています。３０年以内に南海トラフ大規模地震が発生する確率は７０～８０％と言われています。災害時、公的な支援物資はすぐに届かないかもしれません。コンビニなどのお店にも人が殺到し、すぐに商品が無くなるかもしれません。各家庭で最低でも３日分の食料備蓄が必要なのです。<br />３か月ごとに、全国から集めた美味しい保存食が届く
            BimiStock なら<br />「いざという時に消費期限切れ」や「慣れない味で食べづらい」といった心配は無用です。<br />新しい保存用セットが届いたら、３か月間保存していた食品を普段の食卓で美味しく食べる。<br />そんな
            BimiStock の新発想ローリングストックでご自身やご家族のためにしっかり備えましょう！</span>
        </div>
        <div id="text_3">
          <span>※ローリングストックとは、日常的に非常食を食べ、食べた分を買い足すというサイクルを繰り返すことで常に新しい食料を備蓄する方法です。廃棄ロスの観点からも注目されてます。</span>
        </div>
      </div>

      <div id="sheet5">
        <v-img id="bg4" src="../assets/images/homemobile/n_21122_bimistock_image_03.png">
          <div id="title4">
            <span>３食×<br />３日分を<br />お届け</span>
          </div>
          <div id="text4" class="mx-6 mt-6">
            <span>
              １日３食×３日分の美味しい保存食を３か月ごとにお届けします。
              <br />
              ９食全部が違う料理なので、飽きることなくお食事を楽しめます。
            </span>
          </div>
          <div style="margin-top: 300px">
            <div class="d-flex justify-center">
              <v-btn depressed color="rgba(255, 64, 0, 0.7)" width="90%" min-height="65px" @click="set">
                <span
                  style="
                    text-align: center;
                    font-family: 'Noto Sans JP', sans-serif;
                    font-style: normal;
                    font-weight: 900;
                    margin-bottom: 3px;
                    font-size: 20px;
                    color: #fff;
                  "
                >お試しセット内容を見る</span>
              </v-btn>
            </div>
            <div class="d-flex justify-center mt-6">
              <v-btn depressed color="rgba(255, 64, 0, 0.7)" width="90%" min-height="65px" @click="subscription">
                <span
                  style="
                    text-align: center;
                    font-family: 'Noto Sans JP', sans-serif;
                    font-style: normal;
                    font-weight: 900;
                    margin-bottom: 3px;
                    font-size: 20px;
                    color: #fff;
                  "
                >今シーズンの定期便商品を見る</span>
              </v-btn>
            </div>
          </div>
        </v-img>
      </div>

      <div id="sheet6">
        <div id="back6">
          <span>Delicious <br />Preservative <br />Foods</span>
        </div>
        <div id="title5">
          <span>普段使いの<br />アレンジレシピ</span>
        </div>
        <div id="back7" style="margin-top: 50px">
          <span>美味しい食卓</span>
        </div>
        <div id="text5" style="margin-top: -210px">
          <span>
            BimiStockの商品を使ったワンランク上の
            <br />アレンジレシピを<br />
            公式ホームページ内でご紹介していきます。
            <br />どうぞお楽しみに。
          </span>
        </div>
        <div class="mt-12 pt-10">
          <div>
            <v-img
              class="image07"
              src="../assets/images/homemobile/n_21122_bimistock__arrange.png"
              @click="toRecipeScroll('1')"
            ></v-img>
            <div class="text_5">
              <span>らふてー三つ葉うどん</span>
            </div>
          </div>
          <div>
            <v-img
              class="image07"
              src="../assets/images/homemobile/n_21122_bimistock__arrange_eu.png"
              @click="toRecipeScroll('2')"
            ></v-img>
            <div class="text_5">
              <span>ガリバタキャベツスープカレー</span>
            </div>
          </div>
          <div>
            <v-img
              class="image07"
              src="../assets/images/homemobile/n_21122_bimistock__arrange_ex.png"
              @click="toRecipeScroll('3')"
            ></v-img>
            <div class="text_5">
              <span>チンして混ぜるだけステーキペッパーライス</span>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <a href="https://office-rm.com/wp/?page_id=17" target="_blank"
            ><v-img id="imaizumi" src="../assets/images/homemobile/imaizumi.png"></v-img
          ></a>
          <div id="imaizumi2" class="mx-12 my-6">
            <span>アレンジレシピを考案<br />「防災クッキング」でもおなじみ</span>
          </div>
          <div id="imaizumi3" class="mx-12 my-6">
            <span>管理栄養士</span><br /><span onclick="window.open('https://office-rm.com/wp/?page_id=17')">今泉マユ子さん</span>
          </div>
          <div id="imaizumi4" class="mx-12 my-6">
            <span>BimiStockは、全国の美味しい保存食品が届くので発見と楽しさがいっぱいです。届いたとき「何が入っているだろう」とワクワク、箱を開けたとき「すごい!
              こんな食品もあるのね」とワクワク、食べて「美味しい！」とワクワク、「次はどんなのが届くかな」と、ワクワクの連続♪
              新しい味との出会い、美味しい食事は心を豊かにしてくれますよね。<br />時には、ゆずこしょう、ラー油、酢、スパイスなどの調味料で味変したり、トッピングなどをちょい足しして、自分の好きな味に変えてみて下さい。野菜をプラス、食感をプラスするのもおススメです。<br />
              災害時に食べる食事は、決して“我慢して”食べるものではありません。ストレスが続く災害時の生活において日常を取り戻せる”いつもの味”は、家族みんなの心をほっとさせてくれるでしょう。３か月ごとに届くBimiStockは、食料を無駄にせずに災害に備えることができるローリングストックを手軽にできる点がいいですね。「届く・備える・食べる」を習慣化して、いざという時も憂いなしですね。<br />
            </span>
          </div>
        </div>
      </div>

      <div id="sheet7">
        <v-img id="bg5" src="../assets/images/homemobile/AdobeStock_316641157.png">
          <div id="title6" class="mx-6" style="margin-top: 425px">
            <span>いざという時にも<br />美味しいごはんを</span><span style="font-size: 37px">。</span>
          </div>
          <div id="text6" class="mx-6 my-6">
            <span>BimiStock ビミストック
              は、３か月ごとに、選りすぐりの美味しい保存食をお届けする、手間いらずの防災食定期便です。</span>
          </div>
          <div class="pl-6 pr-9">
            <v-img src="../assets/images/homemobile/n_3258_do.png"></v-img>
          </div>
        </v-img>
      </div>

      <v-sheet>
        <div width="100%" style="text-align: center" class="mt-10">
          <span id="back8">Notification</span>
        </div>
        <div width="100%" style="text-align: center; margin-top: -45px">
          <span id="title8">お知らせ</span>
        </div>
        <div class="my-12">
          <div class="text8 my-6 d-flex justify-center">
            <span> 2023年3月11日サービスを開始致しました。 </span>
          </div>
        </div>
        <div width="100%" style="text-align: left">
          <span id="back9">Disaster<br />Prevenation</span>
        </div>
        <div width="100%" style="text-align: center; margin-top: -105px">
          <span id="title9">防災をもっと知ろう！<br />防災リンク集</span>
        </div>
        <div class="mt-10 pointer">
          <v-img
            style="width: 95%; height: 100px; margin: auto"
            src="./images/home/AdobeStock_507199001_Preview.png"
            onclick="window.open('https://www.gov-online.go.jp/tokusyu/cu_bosai/index.html')"
          >
            <div style="text-align: center; margin-top: 37px">
              <span id="tip">防災・減災お役立ち情報</span>
            </div>
          </v-img>
        </div>
        <div class="my-10 pointer">
          <v-img
            style="width: 205px; margin: auto"
            src="./images/home/n_1_et.png"
            onclick="window.open('https://www.jma.go.jp/jma/menu/menuflash.html')"
          ></v-img>
        </div>
        <div class="mb-10 pointer">
          <v-img
            style="width: 305px; margin: auto"
            src="./images/home/n_2_eu.png"
            onclick="window.open('https://www.bousai.go.jp/oyakudachi/info_general.html')"
          ></v-img>
        </div>
      </v-sheet>
      <v-sheet height="140px" color="#ff6e40" width="100%" @click="set">
        <div class="d-flex justify-center mt-2">
          <span class="set">お試しセットを申し込む</span>
        </div>
      </v-sheet>
    </div>
    <div style="position: absolute; top: 9715px; left: 0; right: 0; width: 430px; margin: auto">
      <Footer />
    </div>
  </div>
</template>
<script>
import NavbarMobile from '../components/NavbarMobile.vue'
import Footer from '../components/FooterMobile.vue'

export default {
  components: {
    NavbarMobile,
    Footer,
  },
  data: () => ({
  }),
  mounted() {
    this.toPoint()
  },
  methods: {
    set() {
      this.$router.push('/setlist')
    },
    subscription() {
      this.$router.push('/subscriptionlist')
    },
    toRecipeScroll(val) {
      this.$router.push({
        path: '/recipe',
        query: {
          Anchor: val,
        },
      })
    },
    toPoint() {
      const key = this.$route.query.Anchor
      if (key === '0') {
        const bookMark = document.getElementById('title1')
        bookMark.scrollIntoView()
      }
      if (key === '1') {
        const bookMark = document.getElementById('title2')
        bookMark.scrollIntoView()
      }
      if (key === '2') {
        const bookMark = document.getElementById('title5')
        bookMark.scrollIntoView()
      }
      if (key === '3') {
        const bookMark = document.getElementById('title9')
        bookMark.scrollIntoView()
      }
      if (key === '4') {
        const bookMark = document.getElementById('title3')
        bookMark.scrollIntoView()
      }
      if (key === '5') {
        const bookMark = document.getElementById('imaizumi')
        bookMark.scrollIntoView()
      }
    },
    scroll(key) {
      const PageId = document.querySelector(`#page${key}`)
      window.scrollTo({
        top: PageId.offsetTop,
        behavior: 'smooth',
      })
    },
  },
}
</script>
<style scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: absolute;
  width: 430px;
  height: 10000px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
#bg1 {
  overflow: visible;
  position: absolute;
  width: 430px;
  height: 910px;
  left: 0px;
  top: 0px;
}
#title1 {
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.7));
  overflow: visible;
  position: absolute;
  top: 102px;
  left: 0;
  right: 0;
  margin: auto;
  width: 386px;
  white-space: nowrap;
  text-align: left;
  line-height: 66px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  color: #fff;
}
#back1 {
  opacity: 0.08;
  overflow: visible;
  position: absolute;
  width: 531px;
  top: -33px;
  left: -58px;
  right: 0;
  margin: auto;
  white-space: nowrap;
  text-align: left;
  line-height: 331px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 265px;
  color: rgba(0, 0, 0, 1);
}
#title2 {
  overflow: visible;
  position: absolute;
  width: 309px;
  top: -45px;
  left: 0;
  right: 0;
  margin: auto;
  white-space: nowrap;
  text-align: center;
  line-height: 54px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  color: black;
}
#image01 {
  overflow: visible;
  position: absolute;
  width: 322px;
  height: 348px;
  top: 75px;
  left: 0;
  right: 0;
  margin: auto;
}
#image02 {
  overflow: visible;
  position: absolute;
  width: 350px;
  height: 87px;
  top: 440px;
  left: 0;
  right: 0;
  margin: auto;
}
#text1 {
  overflow: visible;
  position: absolute;
  width: 343px;
  top: 550px;
  left: 0;
  right: 0;
  margin: auto;
  white-space: nowrap;
  text-align: center;
  line-height: 22px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: black;
}
#back2 {
  opacity: 0.08;
  overflow: visible;
  position: absolute;
  width: 430px;
  top: 580px;
  left: 0;
  right: 0;
  margin: auto;
  white-space: nowrap;
  text-align: left;
  line-height: 57px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 83px;
  color: black;
}
#image03 {
  overflow: visible;
  position: absolute;
  width: 391.25px;
  height: 48.387px;
  top: 700px;
  left: 0;
  right: 0;
  margin: auto;
}
.bg2 {
  overflow: visible;
  position: absolute;
  width: 430px;
  height: 600px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.square1 {
  overflow: visible;
  position: absolute;
  width: 364px;
  height: 420px;
  bottom: 28px;
  left: 0;
  right: 0;
  margin: auto;
}
.square1_1 {
  overflow: visible;
  position: absolute;
  width: 364px;
  height: 380px;
  bottom: 28px;
  left: 0;
  right: 0;
  margin: auto;
}
.square2 {
  opacity: 0.7;
  fill: #fff;
}
.m_0 {
  opacity: 0.5;
  mix-blend-mode: multiply;
  overflow: hidden;
  position: absolute;
  width: 276px;
  height: 221px;
  top: 42px;
  left: 15px;
  margin: auto;
  text-align: left;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 200px;
  color: rgba(255, 104, 47, 1);
  text-transform: uppercase;
}
.m_0_1 {
  opacity: 0.5;
  mix-blend-mode: multiply;
  overflow: hidden;
  position: absolute;
  width: 276px;
  height: 221px;
  top: 82px;
  left: 15px;
  margin: auto;
  text-align: left;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 200px;
  color: rgba(255, 104, 47, 1);
  text-transform: uppercase;
}
.merit {
  opacity: 0.6;
  position: absolute;
  width: 110px;
  top: 90px;
  left: 10px;
  margin: auto;
  white-space: nowrap;
  text-align: left;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  color: #fff;
  text-transform: capitalize;
}
.merit_1 {
  opacity: 0.6;
  position: absolute;
  width: 110px;
  top: 130px;
  left: 10px;
  margin: auto;
  white-space: nowrap;
  text-align: left;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  color: #fff;
  text-transform: capitalize;
}
.m_title {
  overflow: hidden;
  position: absolute;
  width: 320px;
  height: 200px;
  top: 165px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: left;
  line-height: 50px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: black;
}
.m_title_1 {
  overflow: hidden;
  position: absolute;
  width: 320px;
  height: 200px;
  top: 205px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: left;
  line-height: 50px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: black;
}
.m_text {
  overflow: hidden;
  position: absolute;
  width: 330px;
  height: 184px;
  top: 375px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: left;
  line-height: 25px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: black;
}
.m_text_1 {
  overflow: hidden;
  position: absolute;
  width: 330px;
  height: 184px;
  top: 415px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: left;
  line-height: 25px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: black;
}
#bg3 {
  opacity: 0.504;
  overflow: visible;
  position: absolute;
  width: 430px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
#back3 {
  opacity: 0.08;
  overflow: visible;
  position: absolute;
  width: 483px;
  top: -45px;
  left: -30px;
  white-space: nowrap;
  text-align: left;
  line-height: 301px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 241px;
  color: black;
}
#back4 {
  opacity: 0.08;
  overflow: visible;
  position: absolute;
  width: 577px;
  top: 220px;
  left: -73px;
  white-space: nowrap;
  text-align: left;
  line-height: 240px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 192px;
  color: black;
}
#back5 {
  opacity: 0.08;
  overflow: visible;
  position: absolute;
  width: 445px;
  top: 450px;
  left: -8px;
  white-space: nowrap;
  text-align: left;
  line-height: 120px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 131px;
  color: black;
}
#title3 {
  mix-blend-mode: multiply;
  overflow: visible;
  position: absolute;
  width: 369px;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  white-space: nowrap;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 37px;
  color: rgba(255, 104, 47, 1);
}
#text2 {
  overflow: visible;
  position: absolute;
  width: 365px;
  height: 172px;
  top: 88px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  line-height: 28px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: black;
}
#image04 {
  overflow: visible;
  position: absolute;
  width: 364px;
  height: 333px;
  top: 290px;
  left: 0;
  right: 0;
  margin: auto;
}
#image05 {
  overflow: visible;
  position: absolute;
  width: 364px;
  height: 333px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
#mark1 {
  overflow: visible;
  position: absolute;
  width: 24px;
  top: 25px;
  left: 0;
  right: 0;
  margin: auto;
  white-space: nowrap;
  text-align: left;
  line-height: 17px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: rgba(118, 118, 118, 1);
}
#mark2 {
  overflow: visible;
  position: absolute;
  width: 24px;
  top: 235px;
  left: -255px;
  right: 0;
  margin: auto;
  white-space: nowrap;
  text-align: left;
  line-height: 17px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: rgba(118, 118, 118, 1);
}
#mark3 {
  overflow: visible;
  position: absolute;
  width: 24px;
  top: 235px;
  left: 0;
  right: -220px;
  margin: auto;
  white-space: nowrap;
  text-align: left;
  line-height: 17px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: rgba(118, 118, 118, 1);
}
#image06 {
  overflow: visible;
  position: absolute;
  width: 82px;
  height: 87px;
  top: 155px;
  left: 0;
  right: 0;
  margin: auto;
}
#text3 {
  overflow: visible;
  position: absolute;
  width: 365px;
  height: 479px;
  top: 670px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  line-height: 30px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: black;
}
#text_3 {
  overflow: visible;
  position: absolute;
  width: 365px;
  height: 87px;
  top: 1180px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: left;
  line-height: 20px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: black;
}
#bg4 {
  height: 990px;
}
#title4 {
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3));
  white-space: nowrap;
  text-align: center;
  line-height: 104px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 82px;
  color: #fff;
}
#text4 {
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3));
  text-align: center;
  line-height: 38px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #fff;
}
#back6 {
  opacity: 0.08;
  margin-top: 30px;
  white-space: nowrap;
  text-align: center;
  line-height: 65px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  color: black;
}
#title5 {
  margin-top: -175px;
  text-align: center;
  line-height: 65px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  color: black;
}
#text5 {
  line-height: 23px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  color: black;
}
.image07 {
  width: 350px;
  height: 350px;
  margin: auto;
}
.text_5 {
  width: 350px;
  margin: auto;
  white-space: nowrap;
  margin-top: -15px;
  text-align: left;
  line-height: 60px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: rgba(53, 78, 87, 1);
}
#back7 {
  opacity: 0.08;
  white-space: nowrap;
  text-align: center;
  line-height: 200px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  color: black;
}
#imaizumi {
  width: 306px;
  height: 306px;
  margin: auto;
}
#imaizumi2 {
  text-align: left;
  line-height: 25px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: black;
}
#imaizumi3 {
  text-align: left;
  line-height: 35px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  color: black;
}
#imaizumi4 {
  text-align: left;
  line-height: 25px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
}
#bg5 {
  width: 100%;
  height: 850px;
}
#title6 {
  white-space: nowrap;
  text-align: left;
  line-height: 55px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 44px;
  color: black;
}
#text6 {
  text-align: left;
  line-height: 35px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: black;
}
#back8 {
  opacity: 0.08;
  white-space: nowrap;
  text-align: center;
  line-height: 65px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  color: black;
}
#title8 {
  width: 180px;
  white-space: nowrap;
  line-height: 50px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  color: black;
}
.text8 {
  max-width: 380px;
  margin: auto;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: black;
}
#title9 {
  width: 721px;
  white-space: nowrap;
  line-height: 54px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  color: black;
}
#back9 {
  opacity: 0.08;
  white-space: nowrap;
  text-align: center;
  line-height: 65px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  color: black;
}
#tip {
  width: 100%;
  white-space: nowrap;
  line-height: 24px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: #fff;
}
.set {
  width: 252px;
  height: 120px;
  margin: auto;
  line-height: 60px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  color: #fff;
}
.pointer :hover {
  cursor: pointer;
}
</style>
